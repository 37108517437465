<template>
<div class="mobile-menu" v-bind:class="{ 'hidden-mobile': !showComponent }">

    <div class="tag">
        <a target="_blank" class="tag_label" @click="openModal(true)">
            <button class="tag_remove">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 24 26" fill="none">
                    <path d="M16.1781 0.364631C13.1085 -0.122117 9.99488 -0.12097 6.92237 0.364631L6.44957 0.439339C3.7747 0.862598 1.62643 2.7252 0.97756 5.18533C-0.325853 10.1341 -0.325853 15.3657 0.97756 20.3146C1.62643 22.7747 3.77455 24.6372 6.44811 25.0599L6.92368 25.1353C8.45986 25.3786 10.0046 25.5 11.5508 25.5C13.097 25.5 14.6433 25.3786 16.178 25.1359C18.082 24.8346 19.7659 23.7869 20.7967 22.2618C21.2057 21.6561 20.9784 20.8742 20.287 20.5151C19.5955 20.1565 18.7038 20.3577 18.295 20.9621C17.6944 21.8498 16.7347 22.4567 15.6613 22.6267C12.9352 23.0575 10.168 23.0575 7.44199 22.6267L6.96642 22.5507C5.43591 22.3091 4.19778 21.2061 3.81163 19.7407C2.60621 15.1672 2.60621 10.3329 3.81163 5.7594C4.19764 4.29381 5.43577 3.19078 6.96773 2.94855L7.44054 2.87321C10.1666 2.44243 12.9352 2.44243 15.6613 2.87321C16.6453 3.02951 17.5227 3.54048 18.1303 4.31369C18.5875 4.89198 19.4918 5.03578 20.1535 4.63802C20.8137 4.23771 20.9799 3.4441 20.5241 2.86454C19.4721 1.52923 17.9288 0.641535 16.1781 0.364631Z" fill="white"/>
                    <path d="M24 12.7503C24 12.0049 23.3291 11.4005 22.5 11.4005H11.4066C12.3828 10.1181 13.8091 9.13114 15.4748 8.63173C16.2599 8.39579 16.6847 7.63196 16.4225 6.92482C16.1603 6.21768 15.3181 5.83206 14.5256 6.07069C11.3054 7.03672 8.73938 9.32942 7.62701 12.2132C7.62251 12.2226 7.61891 12.2317 7.61456 12.2411C7.60391 12.2692 7.58846 12.2951 7.57796 12.3233C7.47401 12.6008 7.47401 12.8999 7.57796 13.1775C7.58846 13.2055 7.60376 13.2316 7.61456 13.2597C7.61891 13.2691 7.62251 13.2781 7.62701 13.2876C8.73938 16.1714 11.3054 18.4639 14.5256 19.4301C14.6839 19.4776 14.8435 19.5 15.0002 19.5C15.6286 19.5 16.2131 19.1422 16.4225 18.576C16.6847 17.8688 16.2599 17.105 15.4748 16.869C13.8091 16.3698 12.3826 15.3828 11.4066 14.1003H22.5C23.3291 14.1 24 13.4956 24 12.7503Z" fill="white"/>
                </svg>
            </button>
            <span class="tag_label">{{$t('LOGIN')}}</span>
        </a>
    </div>
    <div class="tag">
        <a target="_blank" class="tag_label" @click="openModal(false)">
            <button class="tag_remove">
                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                    <g clip-path="url(#clip0_0_306)">
                        <path d="M24 18C26.7503 18 29 15.7499 29 13C29 10.2501 26.7503 8 24 8C21.2497 8 19 10.2501 19 13C19 15.7499 21.2497 18 24 18ZM24 20.5C20.6876 20.5 14 22.1876 14 25.5V28H34V25.5C34 22.1876 27.3124 20.5 24 20.5Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_0_306">
                            <rect width="33.0882" height="33.0882" fill="white" transform="translate(0.138367 0.541504)"/>
                        </clipPath>
                    </defs>
                </svg>
            </button>
            <span class="tag_label">Betslip</span>
        </a>
    </div>
    <menu-component/>
    <ModalComponent ref="modal" />
</div>
</template>

<script>
    import MenuComponent from '@/components/menu.vue'
    import ModalComponent from '@/components/modal.vue'
    export default {
        name: 'MobileMenu',
        showComponent: true,
        components: {
            MenuComponent,
            ModalComponent
        },
        methods: {
            openModal(type) {
                this.$refs.modal.openModal(type);
            }
        },
        props: {
            items: {
            type: Array,
            required: true
            }
        },
        data() {
            return {
            isOpen: false
            }
        }
    };
</script>