<template>
  <brand-header :image="urlImage" :altImage="altImagen"/>
  <header class="header">
    <div class="header-content max-container">
      <div class="header-content__nav">
        <router-link class="header-content__container-logo" to="/">
          <img class="header-content__img" src="../assets/images/logo.png" alt="gameday.bet" />
        </router-link>
      </div>
      <div class="header-content__cta">
        <div class="container-lag">
              <div class="container-langs" v-if="this.$i18n.locale =='en'">
                <div class="lang" @click="changeLanguage('en')">
                  <img src="../assets/images/eeuu.png" alt="">
                  English </div>
                <div class="lang" @click="changeLanguage('es')">
                  <img src="../assets/images/spain.png" alt="">
                  Español </div>
                <div class="lang" @click="changeLanguage('pt')">
                  <img src="../assets/images/brazil.png" alt="">
                  Portuguese </div>
              </div>
              <div class="container-langs" v-if="this.$i18n.locale =='es'">
                <div class="lang" @click="changeLanguage('es')">
                  <img src="../assets/images/spain.png" alt="">
                  Español </div>
                  <div class="lang" @click="changeLanguage('en')">
                  <img src="../assets/images/eeuu.png" alt="">
                  English </div>
                  <div class="lang" @click="changeLanguage('pt')">
                  <img src="../assets/images/brazil.png" alt="">
                  Portuguese </div>
              </div>
              <div class="container-langs" v-if="this.$i18n.locale =='pt'">
                <div class="lang" @click="changeLanguage('pt')">
                  <img src="../assets/images/brazil.png" alt="">
                  Portuguese </div>
                <div class="lang" @click="changeLanguage('es')">
                  <img src="../assets/images/spain.png" alt="">
                  Español </div>
                <div class="lang" @click="changeLanguage('en')">
                  <img src="../assets/images/eeuu.png" alt="">
                  English </div>
              </div>
              <svg class="flecha"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="blue_copy" style="enable-background:new 0 0 100 100;" version="1.1" viewBox="0 0 100 100" xml:space="preserve"><g id="Layer_4_copy"><path d="M31.356,25.677l38.625,22.3c1.557,0.899,1.557,3.147,0,4.046l-38.625,22.3c-1.557,0.899-3.504-0.225-3.504-2.023V27.7   C27.852,25.902,29.798,24.778,31.356,25.677z"/><path d="M69.981,47.977l-38.625-22.3c-0.233-0.134-0.474-0.21-0.716-0.259l37.341,21.559c1.557,0.899,1.557,3.147,0,4.046   l-38.625,22.3c-0.349,0.201-0.716,0.288-1.078,0.301c0.656,0.938,1.961,1.343,3.078,0.699l38.625-22.3   C71.538,51.124,71.538,48.876,69.981,47.977z"/><path d="M31.356,25.677l38.625,22.3c1.557,0.899,1.557,3.147,0,4.046   l-38.625,22.3c-1.557,0.899-3.504-0.225-3.504-2.023V27.7C27.852,25.902,29.798,24.778,31.356,25.677z" style="fill:none;stroke:#000000;stroke-miterlimit:10;"/></g></svg>
              <!-- Resto del contenido de tu componente -->
        </div>
        <!-- <span class="header-content__language material-symbols-rounded">language</span> -->
        <div class="header-buttons">
          <div class="container-bet-lag">
            <a href="#" class="btn-bet secondary-button secondary-button__betslip" @click="openModal(false)">Betslip</a>
            <a class="btn-login secondary-button secondary-button__agent" @click="openModal(true)">{{$t('LOGIN')}}</a>
          </div>
          
        </div>
      </div>
    </div>
    <div class="header-content__info">
      <ul class="header-content__info--list">
        <li
        data-aos="zoom-in"
        data-aos-offset="-150"
        :data-aos-delay="100 * index"
        :data-aos-duration="800"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="false"
        data-aos-anchor-placement="top-center"
        class="header-content__info--list-item" @click="isActive = !isActive" v-for="(route, index) in routeshead" :key="index">
          <router-link :to="route.route">{{ route.item }}</router-link>
        </li>
      </ul>
    </div>
    <ModalComponent ref="modal" />
  </header>
</template>

<script>
  import ModalComponent from '@/components/modal.vue'
  import BrandHeader from '@/components/brand-header.vue';
  // import MenuComponent from '@/components/menu.vue'
  import { useI18n } from 'vue-i18n';
  import { reactive, watch } from 'vue';
  import {computed} from 'vue'

  export default {
    name: "HeaderComponent",
    components: {
      ModalComponent,
      BrandHeader
      // MenuComponent
    },
    watch: {
          $route(newVar){
          let pathName = computed(() =>newVar);
          this.detectLang(pathName.value.name);
        }
      },
    methods: {

      openModal(type) {
        this.$refs.modal.openModal(type);
      },
      changeLanguage(locale) {
        if(this.$i18n.locale!= locale){
          let tempLocaleCurrent = this.$i18n.locale;
          this.$i18n.locale = locale;
          
          const t = this.$t;
          const currentRoute = this.$router.currentRoute.value;
          let templang= currentRoute.name.replace('-lang'+ tempLocaleCurrent, "");

          if (currentRoute.path !== '/' && currentRoute.path !== '/apk') {
            let langChange = `routes.${locale}.${templang}`;
            const translatedPath = `/${t(langChange)}`;
            this.$router.push(translatedPath);
          }
        }

        
      },
      getTranslatedRoutes(t) {
          
          const routes = this.$router.options.routes.map((route) => ({
            ...route,
            path: route.path !== '/' ? `/${t(`routes.${route.name}`)}` : '/',
          }));

          return routes;
        },
        detectLang(pathName){
          if(pathName.includes("langes")){
            this.$i18n.locale = "es";
            this.changeLanguage("es")
          }else if(pathName.includes("langen")){
            this.$i18n.locale = "en";
            this.changeLanguage("en")
          }else if(pathName.includes("langpt")){
            this.$i18n.locale = "pt";
            this.changeLanguage("pt")
          }else{
            console.log("default")
          }
      }
    },
    setup() {
      const DGS_SITEID = 1029;
      const backendUrl = "gameday.bet";
      const { t , locale }  = useI18n();

      const routeshead= reactive(
        [
          {
            item       :  t("routeshead.0.name"),
            route      :  t('routeshead.0.route')
          },
          {
            item       :  t('routeshead.1.name'),
            route      :  t('routeshead.1.route')
          },
          {
            item       :  t('routeshead.2.name'),
            route      :  t('routeshead.2.route')
          },
          {
            item       :  t('routeshead.3.name'),
            route      :  t('routeshead.3.route')
          }
        ]
      ) 
       // Observar los cambios de idioma y actualiza los slides
       const updateSlides = () => {
        routeshead.forEach((routehead, index) => {
            routehead.item = t(`routeshead.${index}.name`);
            routehead.route = t(`routeshead.${index}.route`);
        });
      };

      // Escuchar los cambios de idioma y actualiza los slides
      watch(locale, () => {
        updateSlides();
      });

      return {
        DGS_SITEID,
        backendUrl,
        urlImage: require('../assets/images/logoblanco.png'),
        altImage: 'gameday.bet',
        routeshead
        
      }
    },
  };
</script>
